@import '@/assets/styles/global.scss';

.Menu {
  &-list {
    &_mobile {
      display: flex;
      align-items: center;
      justify-content: space-around;
      min-height: 64px;
    }
  }
  &-item {
    margin-bottom: 10px;
    @include tablet {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--base-default);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 100%;
    padding: 10px;
    @extend %transition;
    @include tablet {
      padding: 0;
    }

    svg {
      path {
        stroke: black;
        @extend %transition;
      }
    }

    &.active {
      svg {
        path {
          stroke: #1a82ff;
        }
      }
    }

    &:hover {
      color: #1a82ff;
      svg {
        path {
          stroke: #1a82ff;
        }
      }
    }
  }

  &-title {
    display: block;
    margin-top: 5px;
  }
}
