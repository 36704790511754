@import '@/assets/styles/global.scss';

.Sidebar {
  display: flex;
  padding: 30px 0px 10px 0px;
  flex-direction: column;
  align-items: center;

  &-menu {
    margin-top: 40px;
  }

  &-account {
    margin-top: auto;
  }
}
