.Logo {
  &_loading {
    animation: bounce 1.5s infinite ease;
  }
}

@keyframes bounce {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
