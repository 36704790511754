@import '@/assets/styles/global.scss';

.Dashboard {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  &-title {
    margin-bottom: 20px;
    &_top {
      padding: 20px 20px 0;
    }
  }

  &-card {
    &:first-child {
      grid-column: 1/5;
      grid-row: 1/5;

      @include desktop {
        grid-column: 1/-1;
        grid-row: initial;
      }
    }

    &:nth-child(2) {
      grid-row: 1/3;
      grid-column: 5/7;

      @include desktop {
        grid-row: initial;
        grid-column: initial;
      }
    }

    &:nth-child(3) {
      grid-row: 3/5;
      grid-column: 5/7;

      @include desktop {
        grid-row: initial;
        grid-column: initial;
      }
    }

    &:nth-child(4) {
      grid-column: 1/-1;
    }

    &:last-child {
      grid-column: 1/-1;
    }

    &_padding {
      padding: 20px;
    }

    &_flex {
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include rwd(600) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-total {
    padding: 20px;
    &_empty {
      padding: 0;
    }
  }

  &-value {
    color: var(--base-default);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }

  &-link,
  &-key {
    color: var(--base-default);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &-link {
    color: #1a82ff;

    @include rwd(600) {
      margin-top: 10px;
    }
  }
  &-values {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @include widescreen {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

html[data-theme='light'] {
  .Dashboard {
    &-total {
      box-shadow: none;
    }
  }
}
