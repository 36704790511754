.CreateInvoice {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: flex-end;

  &-overlay {
    background-color: rgba(#000, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-wrap {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    height: 100vh;
    padding: 30px;
    z-index: 99;
  }

  &-top {
    padding: 20px;
    border-radius: 10px;
    background: var(--Base-Accent, #1a82ff);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    color: var(--Button-Accent_Text, #fff);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  &-form {
    margin-top: 30px;
  }

  &-submit {
    min-width: 120px;
  }
}
