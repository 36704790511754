.NoData {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  &-icon {
    display: flex;
    width: 94px;
    height: 94px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;

    background: var(--exchange-field-hover-stroke);

    svg {
      path {
        stroke: var(--no-data-icon-stroke);
      }
    }
  }

  &-title {
    margin-bottom: 10px;
    color: var(--base-default, #2b2b2b);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }

  &-text {
    color: var(--base-default, #2b2b2b);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
