@import '@/assets/styles/global.scss';

.AlertTemplate {
  position: relative;
  z-index: 99999;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 10px;
  backdrop-filter: blur(30px);
  max-width: 350px;
  color: #fff;
  border-radius: 15px;

  &_success {
    background: #269a55;
  }

  &_info {
    background: #1a82ff;
  }

  &_error {
    background: #bf380e;
  }

  &-wrap {
    display: flex;
    align-items: center;
  }

  &-message {
    font-size: 12px;
    margin: 0 10px;
  }
}
