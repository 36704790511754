@import '@/assets/styles/global.scss';

.DataGrid {
  // height: 800px;
  overflow: auto;
  $self: &;

  &-table {
    width: calc(100% + 10px);
    border-collapse: separate;
    // border-spacing: 0 7px;
    // table-layout: fixed;
  }

  &-tbody {
    #{$self} {
      &-row {
        &:nth-child(odd) {
          background: var(--Global-Background-Elevation3, #fafafa);
        }
      }
    }
  }

  &-row {
    position: relative;
    #{$self} {
      &-th {
        border-bottom: 2px solid var(--Base-Default, #2b2b2b);
      }
    }
  }

  &-th,
  &-td {
    vertical-align: middle;
    position: relative;
    word-break: break-all;
    @extend %transition;
    position: relative;
    padding: 16px 15px;
    color: var(--Base-Default, #2b2b2b);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 24px;
      background: var(--base-scroll-dividers, #e8e8e8);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &-th {
    white-space: nowrap;
    padding: 12px 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background-color: transparent;
    position: sticky;
    top: 0px;
    z-index: 5;
    &_scroll {
      background-color: #fff;
      backdrop-filter: blur(30px);

      // &:first-child {
      //   border-radius: 10px 0 0 0;
      // }

      // &:last-child {
      //   border-radius: 0 10px 0 0;
      // }
    }
  }

  // &-td {
  // }

  &-btn {
    padding: 0;
    color: var(--color-white);
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;

    &_none {
      pointer-events: none;
    }
  }

  &-icon {
    margin-left: 6px;
    &_none {
      path {
        stroke: #fff;
      }
    }
    &_up {
      path {
        &:first-child {
          stroke: #6af8c8;
        }
      }
    }
    &_down {
      path {
        &:last-child {
          stroke: #6af8c8;
        }
      }
    }
  }
}
