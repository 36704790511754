@import '@/assets/styles/global.scss';

.Status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 0 6px;
  min-height: 20px;
  border-radius: 4px;
  &_success {
    color: var(--badges-aletts-green-text, #269a55);
    background: var(--badges-aletts-green-bg, rgba(125, 216, 54, 0.07));
  }

  &_error {
    color: var(--badges-aletts-red-text, #bf380e);
    background: var(--badges-aletts-red-bg, rgba(221, 88, 46, 0.07));
  }

  &_info {
    color: var(--badges-aletts-blue-text, #2288a8);
    background: var(--badges-aletts-blue-bg, rgba(54, 177, 216, 0.07));
  }

  &_warning {
    background: var(--badges-aletts-orange-bg, rgba(240, 163, 48, 0.1));
    color: var(--badges-aletts-orange-text, #d9781f);
  }
}
