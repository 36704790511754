@import '@/assets/styles/global.scss';

.MerchantSlider {
  &-card {
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &-name {
    color: var(--Base-Default, #2b2b2b);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }
  &-balance {
    color: var(--Base-Default, #2b2b2b);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  &-pagination {
    display: flex;
    align-items: center;
    gap: 10px;
    .swiper-pagination {
      &-bullet {
        width: 6px;
        height: 6px;
        background-color: #e8e8e8;
        border-radius: 50%;
        cursor: pointer;
        &-active {
          background-color: #1a82ff;
          cursor: default;
        }
      }
    }
  }
}
