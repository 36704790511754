@import '@/assets/styles/global.scss';

.Button {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: var(--button-default-text);
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;

  $self: &;
  @extend %transition;

  svg {
    path {
      @extend %transition;
    }
  }

  &_sm {
    border-radius: 5px;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 18px;
  }

  &_lg {
    padding: 5px 14px;
    min-height: 48px;
  }

  &_default {
    background: var(--button-accent-bg);
  }

  &_gray {
    background: var(--button-secondary-bg);
    color: var(--Button-Secondary_Text, #2b2b2b);
  }

  &_fluid {
    width: 100%;
  }

  &::after {
    content: '';
    // position: absolute;
    // right: 10px;
    // top: 50%;
    // margin-top: -11px;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    opacity: 0;
    margin-left: 10px;
    transition-duration: 0.5s;
    transition-property: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    animation-timing-function: linear;
  }

  &_loading {
    &::after {
      opacity: 1;
      position: relative;
    }
  }

  &-icon {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &_left {
      margin-right: 6px;
    }
    &_right {
      margin-left: 6px;
    }
  }
}

// Animations
//**********************//

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
