@import '@/assets/styles/global.scss';

.Select {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 50;
    &_left {
      left: 16px;
      min-width: 18px;
    }
    &_right {
      right: 16px;
    }

    &_square {
      top: 11px;
      right: 16px;
      transform: translate(0);

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      // color: $dark;
    }
  }

  &-label {
    display: flex;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    line-height: 15px;

    &_required {
      position: relative;
      padding-left: 15px;
      &::before {
        position: absolute;
        left: 3px;
        top: -2px;
        content: '*';
        font-weight: normal;
        font-size: $font-size-required-before;
        line-height: 23px;
        letter-spacing: 0.0015em;
        // color: $primary;
      }
    }
  }

  &-tooltip {
    max-width: 200px;
    z-index: 500;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: var(--white-color);
  }

  &-basic {
    &_multi {
      .Select {
        &-el {
          &__multi {
            &-value {
              background: var(--blue-color);
              color: var(--red-color-100);
              border-radius: 5px;
              &__label {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 110%;
                color: var(--blue-color-100);
              }
            }
          }
          &__dropdown {
            &-indicator {
              display: none;
            }
          }

          &__clear {
            &-indicator {
              padding: 0;
              color: var(--red-color-100);
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    &_default {
      #{$self} {
        &-el {
          &__control {
            background-color: var(--white-color);
          }

          &__placeholder {
            color: var(--gray-color-600);
          }
        }
      }
    }

    &_white {
      #{$self} {
        &-el {
          &__control {
            // background-color: $white;
            box-shadow: 0px 1px 1px var(--black-color-100);
            border-radius: 5px;
          }
        }
      }
    }

    &_ios {
      #{$self} {
        &-el {
          &__single-value,
          &__placeholder,
          &__input {
            font-size: 16px;
            line-height: 16px;
          }
          &__control {
            min-height: 50px;
          }
        }
      }
    }

    &_error {
      #{$self} {
        &-el {
          &__indicators {
            display: none;
          }
        }
      }
    }
  }

  &-el {
    &__placeholder {
      color: var(--gray-color-600);
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }
    &__single {
      &-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.009em;
        color: var(--black-color-200);
      }
    }
    &__control {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 20px;
      min-height: 36px;
      border: 1px solid var(--gray-color-100);
    }

    &__input {
      &-container {
        position: absolute;
        opacity: 0;
        z-index: -99999;
      }
    }

    &__indicators {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
    }

    &__value {
      &-container {
        overflow: hidden;
        margin-right: 15px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__menu {
      border: none;
      background-color: var(--white-color);
      border: 1px solid var(--gray-color-100);
      margin-top: 8px;
      top: 100%;
      position: absolute;
      width: 100%;
      z-index: 100;
      &-list {
        max-height: 250px;
        overflow-y: auto;

        /* Works on Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: var(--blue-color-200) var(--primary-color);
        }
        /* Works on Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: var(--blue-color-200);
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--primary-color);
        }
      }
    }

    &__option {
      padding: 10px 20px;
      border-bottom: 1px solid var(--gray-color-100);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.009em;
      // white-space: nowrap;
      cursor: pointer;

      &--is-focused {
      }

      &--is-selected {
        color: var(--primary-color);
      }

      &:last-child {
        border: none;
      }
    }
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}
