.Pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-top: 1px solid var(--base-scroll-dividers, #e8e8e8);

  &-list {
    display: flex;
    align-items: center;
  }

  &-total {
    color: var(--Base-Default, #2b2b2b);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  &-item {
    margin-right: 15px;
    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      svg {
        margin-right: 5px;
      }
    }

    &:last-child {
      svg {
        margin-left: 5px;
      }
    }
  }

  &-link {
    display: flex;
    align-items: center;
    color: var(--Button-Default_Bg, #2b2b2b);
    padding: 0;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    transition: 0.5s ease;

    &:hover,
    &_active {
      color: var(--Button-Accent_Bg, #1a82ff);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}
