.History {
  &-card {
    padding-top: 0;
    &_empty {
      padding: 20px;
    }
  }

  &-value {
    color: var(--Base-Default, #2b2b2b);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  &-small {
    color: var(--Base-Secondary, rgba(43, 43, 43, 0.6));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

html[data-theme='light'] {
}
