// Main color

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --white: #fff;
  --badges-aletts-blue-bg: #36b1d811;
  --badges-aletts-blue-text: #2288a8ff;
  --badges-aletts-green-bg: #7dd83611;
  --badges-aletts-green-text: #269a55ff;
  --badges-aletts-orange-bg: #f0a33019;
  --badges-aletts-orange-text: #d9781fff;
  --badges-aletts-red-bg: #dd582e11;
  --badges-aletts-red-text: #bf380eff;
  --badges-aletts-white-text: #ffffffff;
  --badges-aletts-yellow-bg: #f1d02219;
  --badges-aletts-yellow-text: #dba825ff;
  --base-accent: #1a82ffff;
  --base-default: #2b2b2bff;
  --base-inverse: #ffffffff;
  --base-scroll-dividers: #e8e8e8ff;
  --base-secondary: #2b2b2b99;
  --brand: #003ea0ff;
  --button-accent-bg: #1a82ffff;
  --button-accent-hover-bg: #1a82ffcc;
  --button-accent-press-stroke: #1a82ff33;
  --button-accent-text: #ffffffff;
  --button-default-bg: #2b2b2bff;
  --button-default-hover-bg: #2b2b2bcc;
  --button-default-press-stroke: #2b2b2b33;
  --button-default-text: #ffffffff;
  --button-disabled-bg: #e8e8e87f;
  --button-disabled-text: #c7c7c7ff;
  --button-secondary-bg: #f5f5f5ff;
  --button-secondary-hover-bg: #e8e8e833;
  --button-secondary-press-stroke: #e8e8e87f;
  --button-secondary-text: #2b2b2bff;
  --chart-chart-primary: #1a82ffff;
  --chart-chart-secondary: #1a82ff33;
  --drop-down-background: #ffffffff;
  --drop-down-stroke: #2b2b2b19;
  --exchange-field-default-bg: #2b2b2b07;
  --exchange-field-default-text: #2b2b2bff;
  --exchange-field-hover-bg: #ffffffff;
  --exchange-field-hover-stroke: #1a82ff19;
  --exchange-field-label: #1a82ffff;
  --exchange-field-secondary-text: #2b2b2b99;
  --footer-background: #2b2b2b07;
  --global-background-dashboard: #fafafaff;
  --global-background-elevation-2: #ffffffff;
  --global-background-elevation-3: #fafafaff;
  --global-background-widget: #ffffffff;
  --input-default-background: #ffffffff;
  --input-default-stroke: #2b2b2b19;
  --input-disabled-background: #2b2b2b0c;
  --input-disabled-content: #c2c2c2ff;
  --input-eror-content: #bf380eff;
  --input-eror-stroke: #bf380e33;
  --input-focus-stroke: #1a82ff66;
  --input-hover-stroke: #1a82ff33;
  --tab-chips-tab-accent-text: #1a82ffff;
  --tab-chips-tab-default-text: #2b2b2bff;
  --tab-chips-tab-stroke: #1a82ff19;

  --auth-bg: #e7e7e7;
  --card-bg: #fff;
  --card-light-bg: #fafafa;
  --card-bx: 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.05);

  --no-data-icon-stroke: #1a82ff;
}

html[data-theme='dark'] {
  --global-background-dashboard: #1b1b1b;
  --base-default: #fff;
  --auth-bg: #393939;
  --card-light-bg: #282828;
  --card-bg: #232323;
  --card-bx: 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  --exchange-field-hover-stroke: rgba(47, 141, 255, 0.42);

  --no-data-icon-stroke: #2f8dff;
  --button-secondary-bg: #3e3e3e;
}
// [data-theme='light'] {}

// Typography
// Font, line-height, and color for body text, headings, and more.

$default-font: 'Inter', sans-serif !default;
$font-family-base: $default-font !default;

$font-size-base: 16px !default;
$font-size-xsm: 10px !default;
$font-size-sm: 12px !default;
$font-size-md: 14px !default;
$font-size-lg: 18px !default;
$font-size-xlg: 20px !default;
$font-size-required-before: 23.1361px !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 100 !default;
$font-weight-200: 200 !default;
$font-weight-300: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-600: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-800: 800 !default;
$font-weight-900: 900 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.225 !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 2px !default;
$border-color: var(--white) !default;

$border-radius: 3px !default;
$border-radius-sm: 5px !default;
$border-radius-md: 8px !default;
$border-radius-lg: 12px !default;
$border-radius-xlg: 25px !default;

$gap: 15px;
