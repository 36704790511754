[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ART:before {
  content: '\e900';
}
.icon-INCH:before {
  content: '\e901';
}
.icon-IRE:before {
  content: '\e902';
}
.icon-AAVE:before {
  content: '\e903';
}
.icon-ACA:before {
  content: '\e904';
}
.icon-ACH:before {
  content: '\e905';
}
.icon-ACM:before {
  content: '\e906';
}
.icon-ADA:before {
  content: '\e907';
}
.icon-ADADOWN:before {
  content: '\e908';
}
.icon-ADAUP:before {
  content: '\e909';
}
.icon-ADX:before {
  content: '\e90a';
}
.icon-AE:before {
  content: '\e90b';
}
.icon-AERGO:before {
  content: '\e90c';
}
.icon-aETHc:before {
  content: '\e90d';
}
.icon-AGIX:before {
  content: '\e90e';
}
.icon-AGLD:before {
  content: '\e90f';
}
.icon-AHT:before {
  content: '\e910';
}
.icon-AION:before {
  content: '\e911';
}
.icon-AIOZ:before {
  content: '\e912';
}
.icon-AKRO:before {
  content: '\e913';
}
.icon-AKT:before {
  content: '\e914';
}
.icon-ALCX:before {
  content: '\e915';
}
.icon-ALGO:before {
  content: '\e916';
}
.icon-ALICE:before {
  content: '\e917';
}
.icon-ALPACA:before {
  content: '\e918';
}
.icon-ALPHA:before {
  content: '\e919';
}
.icon-ALPINE:before {
  content: '\e91a';
}
.icon-AMB:before {
  content: '\e91b';
}
.icon-AMO:before {
  content: '\e91c';
}
.icon-AMP:before {
  content: '\e91d';
}
.icon-AMPL:before {
  content: '\e91e';
}
.icon-ANC:before {
  content: '\e91f';
}
.icon-ANKR:before {
  content: '\e920';
}
.icon-ANT:before {
  content: '\e921';
}
.icon-APE:before {
  content: '\e922';
}
.icon-API3:before {
  content: '\e923';
}
.icon-APX:before {
  content: '\e924';
}
.icon-AR:before {
  content: '\e925';
}
.icon-ARDR:before {
  content: '\e926';
}
.icon-ARK:before {
  content: '\e927';
}
.icon-ARNX:before {
  content: '\e928';
}
.icon-ARPA:before {
  content: '\e929';
}
.icon-ASD:before {
  content: '\e92a';
}
.icon-ASK:before {
  content: '\e92b';
}
.icon-ASM:before {
  content: '\e92c';
}
.icon-ASR:before {
  content: '\e92d';
}
.icon-AST:before {
  content: '\e92e';
}
.icon-ASTR:before {
  content: '\e92f';
}
.icon-ATA:before {
  content: '\e930';
}
.icon-ATM:before {
  content: '\e931';
}
.icon-ATOLO:before {
  content: '\e932';
}
.icon-ATOM:before {
  content: '\e933';
}
.icon-AUCTION:before {
  content: '\e934';
}
.icon-AUD:before {
  content: '\e935';
}
.icon-AUDIO:before {
  content: '\e936';
}
.icon-AURORA:before {
  content: '\e937';
}
.icon-AURY:before {
  content: '\e938';
}
.icon-AUTO:before {
  content: '\e939';
}
.icon-AVA:before {
  content: '\e93a';
}
.icon-AVAX:before {
  content: '\e93b';
}
.icon-AXEL:before {
  content: '\e93c';
}
.icon-AXS:before {
  content: '\e93d';
}
.icon-BABY:before {
  content: '\e93e';
}
.icon-BADGER:before {
  content: '\e93f';
}
.icon-BAKE:before {
  content: '\e940';
}
.icon-BAL .path1:before {
  content: '\e941';
  color: rgb(255, 255, 255);
}
.icon-BAL .path2:before {
  content: '\e942';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-BAND:before {
  content: '\e943';
}
.icon-BAR:before {
  content: '\e944';
}
.icon-BAT:before {
  content: '\e945';
}
.icon-BCD:before {
  content: '\e946';
}
.icon-BCH:before {
  content: '\e947';
}
.icon-BEAM:before {
  content: '\e948';
}
.icon-BEL:before {
  content: '\e949';
}
.icon-BETA:before {
  content: '\e94a';
}
.icon-BETH:before {
  content: '\e94b';
}
.icon-BFC:before {
  content: '\e94c';
}
.icon-BICO:before {
  content: '\e94d';
}
.icon-BIFI:before {
  content: '\e94e';
}
.icon-BIOT:before {
  content: '\e94f';
}
.icon-BIT:before {
  content: '\e950';
}
.icon-BLCT:before {
  content: '\e951';
}
.icon-BLK:before {
  content: '\e952';
}
.icon-BLOK:before {
  content: '\e953';
}
.icon-BLZ:before {
  content: '\e954';
}
.icon-BMC:before {
  content: '\e955';
}
.icon-BMX:before {
  content: '\e956';
}
.icon-BNANA:before {
  content: '\e957';
}
.icon-BNB:before {
  content: '\e958';
}
.icon-BNBDOWN:before {
  content: '\e959';
}
.icon-BNBUP:before {
  content: '\e95a';
}
.icon-BNT:before {
  content: '\e95b';
}
.icon-BNX:before {
  content: '\e95c';
}
.icon-BOBA:before {
  content: '\e95d';
}
.icon-BOND:before {
  content: '\e95e';
}
.icon-BOO:before {
  content: '\e95f';
}
.icon-BOR:before {
  content: '\e960';
}
.icon-BORA:before {
  content: '\e961';
}
.icon-BOSON:before {
  content: '\e962';
}
.icon-BRD:before {
  content: '\e963';
}
.icon-BSD:before {
  content: '\e964';
}
.icon-BSV:before {
  content: '\e965';
}
.icon-BSW:before {
  content: '\e966';
}
.icon-BTC:before {
  content: '\e967';
}
.icon-BTCDOWN:before {
  content: '\e968';
}
.icon-BTCP:before {
  content: '\e969';
}
.icon-BTCST:before {
  content: '\e96a';
}
.icon-BTCUP:before {
  content: '\e96b';
}
.icon-BTG:before {
  content: '\e96c';
}
.icon-BTM:before {
  content: '\e96d';
}
.icon-BTRST:before {
  content: '\e96e';
}
.icon-BTS:before {
  content: '\e96f';
}
.icon-BTSE:before {
  content: '\e970';
}
.icon-BTT:before {
  content: '\e971';
}
.icon-BTTC:before {
  content: '\e972';
}
.icon-BTTOLD:before {
  content: '\e973';
}
.icon-BURGER:before {
  content: '\e974';
}
.icon-BUSD:before {
  content: '\e975';
}
.icon-BZRX:before {
  content: '\e976';
}
.icon-BZZ:before {
  content: '\e977';
}
.icon-C98:before {
  content: '\e978';
}
.icon-CAKE:before {
  content: '\e979';
}
.icon-CBG:before {
  content: '\e97a';
}
.icon-CBK:before {
  content: '\e97b';
}
.icon-CEEK .path1:before {
  content: '\e97c';
  color: rgb(255, 255, 255);
}
.icon-CEEK .path2:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(2, 0, 0);
}
.icon-CEEK .path3:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(2, 0, 0);
}
.icon-CEL .path1:before {
  content: '\e97f';
  color: rgb(255, 255, 255);
}
.icon-CEL .path2:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-CELO:before {
  content: '\e981';
}
.icon-CELR:before {
  content: '\e982';
}
.icon-CENNZ:before {
  content: '\e983';
}
.icon-CET:before {
  content: '\e984';
}
.icon-CFG:before {
  content: '\e985';
}
.icon-CFX:before {
  content: '\e986';
}
.icon-CGU:before {
  content: '\e987';
}
.icon-CHESS:before {
  content: '\e988';
}
.icon-CHR .path1:before {
  content: '\e989';
  color: rgb(255, 255, 255);
}
.icon-CHR .path2:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-CHSB:before {
  content: '\e98b';
}
.icon-CHZ:before {
  content: '\e98c';
}
.icon-CITY:before {
  content: '\e98d';
}
.icon-CKB .path1:before {
  content: '\e98e';
  color: rgb(255, 255, 255);
}
.icon-CKB .path2:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-CLT:before {
  content: '\e990';
}
.icon-CLV:before {
  content: '\e991';
}
.icon-COCOS:before {
  content: '\e992';
}
.icon-COMP:before {
  content: '\e993';
}
.icon-CON:before {
  content: '\e994';
}
.icon-COS:before {
  content: '\e995';
}
.icon-COTI .path1:before {
  content: '\e996';
  color: rgb(255, 255, 255);
}
.icon-COTI .path2:before {
  content: '\e997';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-COVAL:before {
  content: '\e998';
}
.icon-CQT:before {
  content: '\e999';
}
.icon-CRE:before {
  content: '\e99a';
}
.icon-CREAM:before {
  content: '\e99b';
}
.icon-CRO:before {
  content: '\e99c';
}
.icon-CRODO:before {
  content: '\e99d';
}
.icon-CRTS:before {
  content: '\e99e';
}
.icon-CRV:before {
  content: '\e99f';
}
.icon-CSPR:before {
  content: '\e9a0';
}
.icon-CTK:before {
  content: '\e9a1';
}
.icon-CTSI .path1:before {
  content: '\e9a2';
  color: rgb(255, 255, 255);
}
.icon-CTSI .path2:before {
  content: '\e9a3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-CTX:before {
  content: '\e9a4';
}
.icon-CTXC:before {
  content: '\e9a5';
}
.icon-CUBE:before {
  content: '\e9a6';
}
.icon-CVC:before {
  content: '\e9a7';
}
.icon-CVP:before {
  content: '\e9a8';
}
.icon-CVX:before {
  content: '\e9a9';
}
.icon-DAD:before {
  content: '\e9aa';
}
.icon-DAI:before {
  content: '\e9ab';
}
.icon-DAO .path1:before {
  content: '\e9ac';
  color: rgb(255, 255, 255);
}
.icon-DAO .path2:before {
  content: '\e9ad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-DAR:before {
  content: '\e9ae';
}
.icon-DASH:before {
  content: '\e9af';
}
.icon-DATA:before {
  content: '\e9b0';
}
.icon-DAWN .path1:before {
  content: '\e9b1';
  color: rgb(255, 255, 255);
}
.icon-DAWN .path2:before {
  content: '\e9b2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-DCR:before {
  content: '\e9b3';
}
.icon-DDX:before {
  content: '\e9b4';
}
.icon-DEGO:before {
  content: '\e9b5';
}
.icon-DENT:before {
  content: '\e9b6';
}
.icon-DERC:before {
  content: '\e9b7';
}
.icon-DERO:before {
  content: '\e9b8';
}
.icon-DEXE:before {
  content: '\e9b9';
}
.icon-DF:before {
  content: '\e9ba';
}
.icon-DFI:before {
  content: '\e9bb';
}
.icon-DG:before {
  content: '\e9bc';
}
.icon-DGB:before {
  content: '\e9bd';
}
.icon-DIA:before {
  content: '\e9be';
}
.icon-DKA:before {
  content: '\e9bf';
}
.icon-DNT:before {
  content: '\e9c0';
}
.icon-DOCK:before {
  content: '\e9c1';
}
.icon-DODO:before {
  content: '\e9c2';
}
.icon-DOGE:before {
  content: '\e9c3';
}
.icon-DOT:before {
  content: '\e9c4';
}
.icon-DOTDOWN:before {
  content: '\e9c5';
}
.icon-DOTUP:before {
  content: '\e9c6';
}
.icon-DPI:before {
  content: '\e9c7';
}
.icon-DPR:before {
  content: '\e9c8';
}
.icon-DREP:before {
  content: '\e9c9';
}
.icon-DUSK:before {
  content: '\e9ca';
}
.icon-DVF:before {
  content: '\e9cb';
}
.icon-DX:before {
  content: '\e9cc';
}
.icon-DXD:before {
  content: '\e9cd';
}
.icon-DYDX:before {
  content: '\e9ce';
}
.icon-EFI:before {
  content: '\e9cf';
}
.icon-EGG:before {
  content: '\e9d0';
}
.icon-EGLD:before {
  content: '\e9d1';
}
.icon-ELF:before {
  content: '\e9d2';
}
.icon-ENJ:before {
  content: '\e9d3';
}
.icon-ENS:before {
  content: '\e9d4';
}
.icon-EOS:before {
  content: '\e9d5';
}
.icon-EPS .path1:before {
  content: '\e9d6';
  color: rgb(255, 255, 255);
}
.icon-EPS .path2:before {
  content: '\e9d7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-EPX:before {
  content: '\e9d8';
}
.icon-ERG:before {
  content: '\e9d9';
}
.icon-ERN:before {
  content: '\e9da';
}
.icon-ETC:before {
  content: '\e9db';
}
.icon-ETH:before {
  content: '\e9dc';
}
.icon-ETHDOWN:before {
  content: '\e9dd';
}
.icon-ETHUP:before {
  content: '\e9de';
}
.icon-ETN:before {
  content: '\e9df';
}
.icon-EUM:before {
  content: '\e9e0';
}
.icon-EUR:before {
  content: '\e9e1';
}
.icon-EXRD:before {
  content: '\e9e2';
}
.icon-EZ:before {
  content: '\e9e3';
}
.icon-FARM:before {
  content: '\e9e4';
}
.icon-FCT:before {
  content: '\e9e5';
}
.icon-FEI:before {
  content: '\e9e6';
}
.icon-FET:before {
  content: '\e9e7';
}
.icon-FIDA:before {
  content: '\e9e8';
}
.icon-FIL:before {
  content: '\e9e9';
}
.icon-FIO:before {
  content: '\e9ea';
}
.icon-FIRO:before {
  content: '\e9eb';
}
.icon-FIS:before {
  content: '\e9ec';
}
.icon-FLETA:before {
  content: '\e9ed';
}
.icon-FLOW:before {
  content: '\e9ee';
}
.icon-FLUID:before {
  content: '\e9ef';
}
.icon-FLUX:before {
  content: '\e9f0';
}
.icon-FOR:before {
  content: '\e9f1';
}
.icon-FORTH:before {
  content: '\e9f2';
}
.icon-FOX:before {
  content: '\e9f3';
}
.icon-FRAX:before {
  content: '\e9f4';
}
.icon-FRONT:before {
  content: '\e9f5';
}
.icon-FRTS:before {
  content: '\e9f6';
}
.icon-FSN:before {
  content: '\e9f7';
}
.icon-FTM:before {
  content: '\e9f8';
}
.icon-FTT:before {
  content: '\e9f9';
}
.icon-FUN:before {
  content: '\e9fa';
}
.icon-FUSE:before {
  content: '\e9fb';
}
.icon-FXS:before {
  content: '\e9fc';
}
.icon-GAL:before {
  content: '\e9fd';
}
.icon-GALA:before {
  content: '\e9fe';
}
.icon-GAS:before {
  content: '\e9ff';
}
.icon-GBP:before {
  content: '\ea00';
}
.icon-GENE:before {
  content: '\ea01';
}
.icon-GHST:before {
  content: '\ea02';
}
.icon-GLM:before {
  content: '\ea03';
}
.icon-GLMR:before {
  content: '\ea04';
}
.icon-GMT:before {
  content: '\ea05';
}
.icon-GNO:before {
  content: '\ea06';
}
.icon-GO:before {
  content: '\ea07';
}
.icon-GRS:before {
  content: '\ea08';
}
.icon-GRT:before {
  content: '\ea09';
}
.icon-GT:before {
  content: '\ea0a';
}
.icon-GTC:before {
  content: '\ea0b';
}
.icon-GTO:before {
  content: '\ea0c';
}
.icon-GUSD:before {
  content: '\ea0d';
}
.icon-GXC:before {
  content: '\ea0e';
}
.icon-GYEN:before {
  content: '\ea0f';
}
.icon-HARD:before {
  content: '\ea10';
}
.icon-HBAR:before {
  content: '\ea11';
}
.icon-HBTC:before {
  content: '\ea12';
}
.icon-HERO:before {
  content: '\ea13';
}
.icon-HEZ:before {
  content: '\ea14';
}
.icon-HIGH:before {
  content: '\ea15';
}
.icon-HIVE:before {
  content: '\ea16';
}
.icon-HNS:before {
  content: '\ea17';
}
.icon-HNT:before {
  content: '\ea18';
}
.icon-HOO:before {
  content: '\ea19';
}
.icon-HOPR:before {
  content: '\ea1a';
}
.icon-HOT:before {
  content: '\ea1b';
}
.icon-HT:before {
  content: '\ea1c';
}
.icon-HTR:before {
  content: '\ea1d';
}
.icon-HUNT:before {
  content: '\ea1e';
}
.icon-HUSD:before {
  content: '\ea1f';
}
.icon-HYDRA:before {
  content: '\ea20';
}
.icon-HYN:before {
  content: '\ea21';
}
.icon-ICHI:before {
  content: '\ea22';
}
.icon-ICP:before {
  content: '\ea23';
}
.icon-ICX:before {
  content: '\ea24';
}
.icon-IDEX:before {
  content: '\ea25';
}
.icon-IDRT:before {
  content: '\ea26';
}
.icon-ILV:before {
  content: '\ea27';
}
.icon-IMX:before {
  content: '\ea28';
}
.icon-INJ:before {
  content: '\ea29';
}
.icon-IOST:before {
  content: '\ea2a';
}
.icon-IOTA:before {
  content: '\ea2b';
}
.icon-IOTX:before {
  content: '\ea2c';
}
.icon-IQ:before {
  content: '\ea2d';
}
.icon-IRIS:before {
  content: '\ea2e';
}
.icon-JASMY:before {
  content: '\ea2f';
}
.icon-JOE:before {
  content: '\ea30';
}
.icon-JST:before {
  content: '\ea31';
}
.icon-JUV:before {
  content: '\ea32';
}
.icon-KAI:before {
  content: '\ea33';
}
.icon-KAVA:before {
  content: '\ea34';
}
.icon-KCS:before {
  content: '\ea35';
}
.icon-KDA:before {
  content: '\ea36';
}
.icon-KEEP:before {
  content: '\ea37';
}
.icon-KEY:before {
  content: '\ea38';
}
.icon-KILT:before {
  content: '\ea39';
}
.icon-KIN:before {
  content: '\ea3a';
}
.icon-KIRO:before {
  content: '\ea3b';
}
.icon-KLAY:before {
  content: '\ea3c';
}
.icon-KLV:before {
  content: '\ea3d';
}
.icon-KMD:before {
  content: '\ea3e';
}
.icon-KNC:before {
  content: '\ea3f';
}
.icon-KP3R:before {
  content: '\ea40';
}
.icon-KRT:before {
  content: '\ea41';
}
.icon-KSM:before {
  content: '\ea42';
}
.icon-KZEN:before {
  content: '\ea43';
}
.icon-LA:before {
  content: '\ea44';
}
.icon-LAT:before {
  content: '\ea45';
}
.icon-LAZIO:before {
  content: '\ea46';
}
.icon-LCX:before {
  content: '\ea47';
}
.icon-LDO:before {
  content: '\ea48';
}
.icon-LEO:before {
  content: '\ea49';
}
.icon-LINA:before {
  content: '\ea4a';
}
.icon-LINK:before {
  content: '\ea4b';
}
.icon-LINKDOWN:before {
  content: '\ea4c';
}
.icon-LINKUP:before {
  content: '\ea4d';
}
.icon-LIT:before {
  content: '\ea4e';
}
.icon-LN:before {
  content: '\ea4f';
}
.icon-LOG:before {
  content: '\ea50';
}
.icon-LOKA:before {
  content: '\ea51';
}
.icon-LON:before {
  content: '\ea52';
}
.icon-LOOM:before {
  content: '\ea53';
}
.icon-LPT:before {
  content: '\ea54';
}
.icon-LQTY:before {
  content: '\ea55';
}
.icon-LRC:before {
  content: '\ea56';
}
.icon-LSK:before {
  content: '\ea57';
}
.icon-LTC:before {
  content: '\ea58';
}
.icon-LTO:before {
  content: '\ea59';
}
.icon-LUNA:before {
  content: '\ea5a';
}
.icon-LUNC:before {
  content: '\ea5b';
}
.icon-LUSD:before {
  content: '\ea5c';
}
.icon-MAID:before {
  content: '\ea5d';
}
.icon-MANA:before {
  content: '\ea5e';
}
.icon-MARO:before {
  content: '\ea5f';
}
.icon-MASK:before {
  content: '\ea60';
}
.icon-MATH:before {
  content: '\ea61';
}
.icon-MATIC:before {
  content: '\ea62';
}
.icon-MBL:before {
  content: '\ea63';
}
.icon-MBOX:before {
  content: '\ea64';
}
.icon-MC:before {
  content: '\ea65';
}
.icon-MDA:before {
  content: '\ea66';
}
.icon-MDT:before {
  content: '\ea67';
}
.icon-MDX:before {
  content: '\ea68';
}
.icon-MED:before {
  content: '\ea69';
}
.icon-META:before {
  content: '\ea6a';
}
.icon-MEV:before {
  content: '\ea6b';
}
.icon-MFT:before {
  content: '\ea6c';
}
.icon-MINA:before {
  content: '\ea6d';
}
.icon-MIOTA:before {
  content: '\ea6e';
}
.icon-MIR:before {
  content: '\ea6f';
}
.icon-MITH:before {
  content: '\ea70';
}
.icon-MIX:before {
  content: '\ea71';
}
.icon-MKR:before {
  content: '\ea72';
}
.icon-MLK:before {
  content: '\ea73';
}
.icon-MLN:before {
  content: '\ea74';
}
.icon-MNGO:before {
  content: '\ea75';
}
.icon-MNW:before {
  content: '\ea76';
}
.icon-MOB:before {
  content: '\ea77';
}
.icon-MOF:before {
  content: '\ea78';
}
.icon-MONA:before {
  content: '\ea79';
}
.icon-MOVEZ:before {
  content: '\ea7a';
}
.icon-MOVR:before {
  content: '\ea7b';
}
.icon-MSOL:before {
  content: '\ea7c';
}
.icon-MTL .path1:before {
  content: '\ea7d';
  color: rgb(255, 255, 255);
}
.icon-MTL .path2:before {
  content: '\ea7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-MULTI:before {
  content: '\ea7f';
}
.icon-MUSD:before {
  content: '\ea80';
}
.icon-MWC:before {
  content: '\ea81';
}
.icon-MX:before {
  content: '\ea82';
}
.icon-MXC:before {
  content: '\ea83';
}
.icon-NAS:before {
  content: '\ea84';
}
.icon-NAV:before {
  content: '\ea85';
}
.icon-NBS:before {
  content: '\ea86';
}
.icon-NCT:before {
  content: '\ea87';
}
.icon-NEAR:before {
  content: '\ea88';
}
.icon-NEBL:before {
  content: '\ea89';
}
.icon-NEO:before {
  content: '\ea8a';
}
.icon-NEXO:before {
  content: '\ea8b';
}
.icon-NFT:before {
  content: '\ea8c';
}
.icon-NIF:before {
  content: '\ea8d';
}
.icon-NKN:before {
  content: '\ea8e';
}
.icon-NMC:before {
  content: '\ea8f';
}
.icon-NMR:before {
  content: '\ea90';
}
.icon-NOIA:before {
  content: '\ea91';
}
.icon-NRG:before {
  content: '\ea92';
}
.icon-NSBT:before {
  content: '\ea93';
}
.icon-NU:before {
  content: '\ea94';
}
.icon-NULS:before {
  content: '\ea95';
}
.icon-NXM:before {
  content: '\ea96';
}
.icon-NXS:before {
  content: '\ea97';
}
.icon-NYE:before {
  content: '\ea98';
}
.icon-OAX:before {
  content: '\ea99';
}
.icon-OCEAN:before {
  content: '\ea9a';
}
.icon-OG:before {
  content: '\ea9b';
}
.icon-OGN:before {
  content: '\ea9c';
}
.icon-OKB:before {
  content: '\ea9d';
}
.icon-OM:before {
  content: '\ea9e';
}
.icon-OMG:before {
  content: '\ea9f';
}
.icon-ONE:before {
  content: '\eaa0';
}
.icon-ONG:before {
  content: '\eaa1';
}
.icon-ONT:before {
  content: '\eaa2';
}
.icon-ONUS:before {
  content: '\eaa3';
}
.icon-OOKI:before {
  content: '\eaa4';
}
.icon-OP:before {
  content: '\eaa5';
}
.icon-ORBS:before {
  content: '\eaa6';
}
.icon-ORC:before {
  content: '\eaa7';
}
.icon-ORN:before {
  content: '\eaa8';
}
.icon-OSMO:before {
  content: '\eaa9';
}
.icon-OUSD:before {
  content: '\eaaa';
}
.icon-OXEN:before {
  content: '\eaab';
}
.icon-OXT:before {
  content: '\eaac';
}
.icon-PAXG:before {
  content: '\eaad';
}
.icon-PEAK:before {
  content: '\eaae';
}
.icon-PEOPLE:before {
  content: '\eaaf';
}
.icon-PERL:before {
  content: '\eab0';
}
.icon-PERP:before {
  content: '\eab1';
}
.icon-PHA:before {
  content: '\eab2';
}
.icon-PHB:before {
  content: '\eab3';
}
.icon-PHL:before {
  content: '\eab4';
}
.icon-PIVX:before {
  content: '\eab5';
}
.icon-PLA:before {
  content: '\eab6';
}
.icon-PNK:before {
  content: '\eab7';
}
.icon-PNT:before {
  content: '\eab8';
}
.icon-POLS:before {
  content: '\eab9';
}
.icon-POLY:before {
  content: '\eaba';
}
.icon-POND:before {
  content: '\eabb';
}
.icon-PORTO:before {
  content: '\eabc';
}
.icon-POWR .path1:before {
  content: '\eabd';
  color: rgb(255, 255, 255);
}
.icon-POWR .path2:before {
  content: '\eabe';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-PRE:before {
  content: '\eabf';
}
.icon-PROM:before {
  content: '\eac0';
}
.icon-PROS:before {
  content: '\eac1';
}
.icon-PRQ:before {
  content: '\eac2';
}
.icon-PRQ-1:before {
  content: '\eac3';
}
.icon-PSG:before {
  content: '\eac4';
}
.icon-PUNDIX:before {
  content: '\eac5';
}
.icon-PYR:before {
  content: '\eac6';
}
.icon-QASH:before {
  content: '\eac7';
}
.icon-QC:before {
  content: '\eac8';
}
.icon-QI:before {
  content: '\eac9';
}
.icon-QKC:before {
  content: '\eaca';
}
.icon-QLC:before {
  content: '\eacb';
}
.icon-QNT:before {
  content: '\eacc';
}
.icon-QOM:before {
  content: '\eacd';
}
.icon-QSP:before {
  content: '\eace';
}
.icon-QTUM:before {
  content: '\eacf';
}
.icon-QUACK:before {
  content: '\ead0';
}
.icon-QUICK:before {
  content: '\ead1';
}
.icon-RAD:before {
  content: '\ead2';
}
.icon-RAI:before {
  content: '\ead3';
}
.icon-RAMP:before {
  content: '\ead4';
}
.icon-RARE:before {
  content: '\ead5';
}
.icon-RARI:before {
  content: '\ead6';
}
.icon-RAY:before {
  content: '\ead7';
}
.icon-RBN:before {
  content: '\ead8';
}
.icon-REAP:before {
  content: '\ead9';
}
.icon-REEF:before {
  content: '\eada';
}
.icon-REI:before {
  content: '\eadb';
}
.icon-REN:before {
  content: '\eadc';
}
.icon-RENBTC:before {
  content: '\eadd';
}
.icon-REP:before {
  content: '\eade';
}
.icon-REQ:before {
  content: '\eadf';
}
.icon-REV:before {
  content: '\eae0';
}
.icon-RFOX:before {
  content: '\eae1';
}
.icon-RFR:before {
  content: '\eae2';
}
.icon-RGT:before {
  content: '\eae3';
}
.icon-RIF:before {
  content: '\eae4';
}
.icon-RISE:before {
  content: '\eae5';
}
.icon-RKN:before {
  content: '\eae6';
}
.icon-RLC:before {
  content: '\eae7';
}
.icon-RLY:before {
  content: '\eae8';
}
.icon-RMRK:before {
  content: '\eae9';
}
.icon-RNDR:before {
  content: '\eaea';
}
.icon-ROOK:before {
  content: '\eaeb';
}
.icon-ROSE:before {
  content: '\eaec';
}
.icon-RSR .path1:before {
  content: '\eaed';
  color: rgb(255, 255, 255);
}
.icon-RSR .path2:before {
  content: '\eaee';
  margin-left: -1em;
  color: rgb(17, 17, 17);
}
.icon-RSS3:before {
  content: '\eaef';
}
.icon-RUNE:before {
  content: '\eaf0';
}
.icon-RVN:before {
  content: '\eaf1';
}
.icon-RVP:before {
  content: '\eaf2';
}
.icon-SAFE:before {
  content: '\eaf3';
}
.icon-SAFEMOON:before {
  content: '\eaf4';
}
.icon-SAFFLE:before {
  content: '\eaf5';
}
.icon-SAMO:before {
  content: '\eaf6';
}
.icon-SAND:before {
  content: '\eaf7';
}
.icon-SANTOS:before {
  content: '\eaf8';
}
.icon-SC:before {
  content: '\eaf9';
}
.icon-SCRT:before {
  content: '\eafa';
}
.icon-SDAO:before {
  content: '\eafb';
}
.icon-SDN:before {
  content: '\eafc';
}
.icon-SERO:before {
  content: '\eafd';
}
.icon-SFP:before {
  content: '\eafe';
}
.icon-SFUND:before {
  content: '\eaff';
}
.icon-SHIB:before {
  content: '\eb00';
}
.icon-SIX:before {
  content: '\eb01';
}
.icon-SKL:before {
  content: '\eb02';
}
.icon-SLP:before {
  content: '\eb03';
}
.icon-SNL:before {
  content: '\eb04';
}
.icon-SNM:before {
  content: '\eb05';
}
.icon-SNT .path1:before {
  content: '\eb06';
  color: rgb(255, 255, 255);
}
.icon-SNT .path2:before {
  content: '\eb07';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-SNX:before {
  content: '\eb08';
}
.icon-SOFI:before {
  content: '\eb09';
}
.icon-SOL:before {
  content: '\eb0a';
}
.icon-SOS:before {
  content: '\eb0b';
}
.icon-SOUL:before {
  content: '\eb0c';
}
.icon-SPARTA:before {
  content: '\eb0d';
}
.icon-SPELL:before {
  content: '\eb0e';
}
.icon-SPS:before {
  content: '\eb0f';
}
.icon-SQR:before {
  content: '\eb10';
}
.icon-SRM:before {
  content: '\eb11';
}
.icon-SSV:before {
  content: '\eb12';
}
.icon-SSX:before {
  content: '\eb13';
}
.icon-STAKE:before {
  content: '\eb14';
}
.icon-STARL:before {
  content: '\eb15';
}
.icon-STEEM:before {
  content: '\eb16';
}
.icon-STETH:before {
  content: '\eb17';
}
.icon-STMX:before {
  content: '\eb18';
}
.icon-STORJ:before {
  content: '\eb19';
}
.icon-STPT:before {
  content: '\eb1a';
}
.icon-STRAX:before {
  content: '\eb1b';
}
.icon-STRK:before {
  content: '\eb1c';
}
.icon-STX:before {
  content: '\eb1d';
}
.icon-SUKU:before {
  content: '\eb1e';
}
.icon-SUN:before {
  content: '\eb1f';
}
.icon-SUPER:before {
  content: '\eb20';
}
.icon-SUSHI:before {
  content: '\eb21';
}
.icon-SWAP:before {
  content: '\eb22';
}
.icon-SWP:before {
  content: '\eb23';
}
.icon-SXP:before {
  content: '\eb24';
}
.icon-SYS:before {
  content: '\eb25';
}
.icon-T:before {
  content: '\eb26';
}
.icon-TCT:before {
  content: '\eb27';
}
.icon-TEL .path1:before {
  content: '\eb28';
  color: rgb(255, 255, 255);
}
.icon-TEL .path2:before {
  content: '\eb29';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-TFUEL:before {
  content: '\eb2a';
}
.icon-THETA:before {
  content: '\eb2b';
}
.icon-TIME:before {
  content: '\eb2c';
}
.icon-TITAN:before {
  content: '\eb2d';
}
.icon-TKO:before {
  content: '\eb2e';
}
.icon-TLM:before {
  content: '\eb2f';
}
.icon-TLOS:before {
  content: '\eb30';
}
.icon-TNB:before {
  content: '\eb31';
}
.icon-TOMO:before {
  content: '\eb32';
}
.icon-TON:before {
  content: '\eb33';
}
.icon-TORN:before {
  content: '\eb34';
}
.icon-TPT:before {
  content: '\eb35';
}
.icon-TRB:before {
  content: '\eb36';
}
.icon-TRIBE:before {
  content: '\eb37';
}
.icon-TROY:before {
  content: '\eb38';
}
.icon-TRU:before {
  content: '\eb39';
}
.icon-TRX:before {
  content: '\eb3a';
}
.icon-TRXDOWN:before {
  content: '\eb3b';
}
.icon-TRXUP:before {
  content: '\eb3c';
}
.icon-TT:before {
  content: '\eb3d';
}
.icon-TTT:before {
  content: '\eb3e';
}
.icon-TUSD:before {
  content: '\eb3f';
}
.icon-TVK:before {
  content: '\eb40';
}
.icon-TWT:before {
  content: '\eb41';
}
.icon-UFI:before {
  content: '\eb42';
}
.icon-UFO:before {
  content: '\eb43';
}
.icon-UFT:before {
  content: '\eb44';
}
.icon-ULT:before {
  content: '\eb45';
}
.icon-UMA:before {
  content: '\eb46';
}
.icon-UNFI:before {
  content: '\eb47';
}
.icon-UNI:before {
  content: '\eb48';
}
.icon-UQC:before {
  content: '\eb49';
}
.icon-USDC:before {
  content: '\eb4a';
}
.icon-USDD:before {
  content: '\eb4b';
}
.icon-USDJ:before {
  content: '\eb4c';
}
.icon-USDN:before {
  content: '\eb4d';
}
.icon-USDP:before {
  content: '\eb4e';
}
.icon-USDT:before {
  content: '\eb4f';
}
.icon-USDT-ERC20:before {
  content: '\eb4f';
}
.icon-USDT-TRC20:before {
  content: '\eb4f';
}
.icon-USTC:before {
  content: '\eb50';
}
.icon-UTK:before {
  content: '\eb51';
}
.icon-VBIT:before {
  content: '\eb52';
}
.icon-VEGA:before {
  content: '\eb53';
}
.icon-VERI:before {
  content: '\eb54';
}
.icon-VET:before {
  content: '\eb55';
}
.icon-VGX:before {
  content: '\eb56';
}
.icon-VIB:before {
  content: '\eb57';
}
.icon-VIDT:before {
  content: '\eb58';
}
.icon-VITE:before {
  content: '\eb59';
}
.icon-VLX .path1:before {
  content: '\eb5a';
  color: rgb(255, 255, 255);
}
.icon-VLX .path2:before {
  content: '\eb5b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-VOXEL:before {
  content: '\eb5c';
}
.icon-VR:before {
  content: '\eb5d';
}
.icon-VRA:before {
  content: '\eb5e';
}
.icon-VTHO:before {
  content: '\eb5f';
}
.icon-vUSDT:before {
  content: '\eb60';
}
.icon-VXV:before {
  content: '\eb61';
}
.icon-vXVS:before {
  content: '\eb62';
}
.icon-WABI:before {
  content: '\eb63';
}
.icon-WAN:before {
  content: '\eb64';
}
.icon-WAVES:before {
  content: '\eb65';
}
.icon-WAXP:before {
  content: '\eb66';
}
.icon-WBNB:before {
  content: '\eb67';
}
.icon-WBTC:before {
  content: '\eb68';
}
.icon-WEMIX:before {
  content: '\eb69';
}
.icon-WILD:before {
  content: '\eb6a';
}
.icon-WIN:before {
  content: '\eb6b';
}
.icon-WING:before {
  content: '\eb6c';
}
.icon-WNXM:before {
  content: '\eb6d';
}
.icon-WOO:before {
  content: '\eb6e';
}
.icon-WOZX:before {
  content: '\eb6f';
}
.icon-WRX:before {
  content: '\eb70';
}
.icon-WTC:before {
  content: '\eb71';
}
.icon-WTRX .path1:before {
  content: '\eb72';
  color: rgb(255, 255, 255);
}
.icon-WTRX .path2:before {
  content: '\eb73';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-WXT .path1:before {
  content: '\eb74';
  color: rgb(255, 255, 255);
}
.icon-WXT .path2:before {
  content: '\eb75';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-XAUT:before {
  content: '\eb76';
}
.icon-XCH .path1:before {
  content: '\eb77';
  color: rgb(255, 255, 255);
}
.icon-XCH .path2:before {
  content: '\eb78';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-XCN:before {
  content: '\eb79';
}
.icon-XDB .path1:before {
  content: '\eb7a';
  color: rgb(255, 255, 255);
}
.icon-XDB .path2:before {
  content: '\eb7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-XDC:before {
  content: '\eb7c';
}
.icon-XEC:before {
  content: '\eb7d';
}
.icon-XEM:before {
  content: '\eb7e';
}
.icon-XHV:before {
  content: '\eb7f';
}
.icon-XLM:before {
  content: '\eb80';
}
.icon-XMR:before {
  content: '\eb81';
}
.icon-XNC:before {
  content: '\eb82';
}
.icon-XNO:before {
  content: '\eb83';
}
.icon-XPR:before {
  content: '\eb84';
}
.icon-XPRT .path1:before {
  content: '\eb85';
  color: rgb(255, 255, 255);
}
.icon-XPRT .path2:before {
  content: '\eb86';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-XRP:before {
  content: '\eb87';
}
.icon-XRPDOWN:before {
  content: '\eb88';
}
.icon-XRPUP:before {
  content: '\eb89';
}
.icon-XTZ:before {
  content: '\eb8a';
}
.icon-XVG:before {
  content: '\eb8b';
}
.icon-XVS:before {
  content: '\eb8c';
}
.icon-XWC:before {
  content: '\eb8d';
}
.icon-XYM:before {
  content: '\eb8e';
}
.icon-XYO:before {
  content: '\eb8f';
}
.icon-YFI:before {
  content: '\eb90';
}
.icon-YFII:before {
  content: '\eb91';
}
.icon-YGG:before {
  content: '\eb92';
}
.icon-YLD:before {
  content: '\eb93';
}
.icon-YOOSHI:before {
  content: '\eb94';
}
.icon-YOUC:before {
  content: '\eb95';
}
.icon-ZAM:before {
  content: '\eb96';
}
.icon-ZB:before {
  content: '\eb97';
}
.icon-ZEC:before {
  content: '\eb98';
}
.icon-ZEN:before {
  content: '\eb99';
}
.icon-ZEON:before {
  content: '\eb9a';
}
.icon-ZIL:before {
  content: '\eb9b';
}
.icon-ZRX:before {
  content: '\eb9c';
}

.icon-EUR:before {
  content: '€';
  font-family: 'Avenir Next Cyr' !important;
  font-weight: 600;
}

.icon-USD:before {
  content: '$';
  // font-family: 'Avenir Next Cyr' !important;
  font-family: 'Inter';
  font-weight: 600;
}
