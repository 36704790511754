@import '@/assets/styles/global.scss';

.AdminDashboard {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  &-title {
    margin-bottom: 20px;
    &_top {
      margin: 0;
    }
  }

  &-card {
    &:first-child {
      grid-area: 1 / 1 / 4 / 7;
    }

    &:nth-child(2) {
      grid-area: 2 / 7 / 3 / 9;
    }

    &:nth-child(3) {
      grid-area: 2 / 9 / 3 / 11;
    }

    &:nth-child(4) {
      grid-area: 3 / 9 / 4 / 11;
    }
    &:nth-child(5) {
      grid-area: 3 / 7 / 4 / 9;
    }

    &:nth-child(6) {
      grid-area: 1 / 7 / 2 / 11;
    }

    &:nth-child(7) {
      grid-area: 4 / 1 / 5 / 6;
    }

    &:last-child {
      grid-area: 4 / 6 / 5 / 11;
    }

    &_padding {
      padding: 20px;
    }

    &_flex {
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include rwd(600) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &-total {
    padding: 20px;
    &_empty {
      padding: 0;
    }
  }

  &-value {
    color: var(--base-default);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }

  &-link,
  &-key {
    color: var(--base-default);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &-link {
    color: #1a82ff;

    &_svg {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
        path {
          fill: #1a82ff;
        }
      }
    }
  }

  &-top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

html[data-theme='light'] {
  .Dashboard {
    &-total {
      box-shadow: none;
    }
  }
}
