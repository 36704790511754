@import '@/assets/styles/global.scss';

.Field {
  position: relative;
  $self: &;

  &_xxl {
    margin-bottom: 40px;
  }
  &_xl {
    margin-bottom: 30px;
  }
  &_lg {
    margin-bottom: 25px;
  }
  &_md {
    margin-bottom: 20px;
  }
  &_sm {
    margin-bottom: 14px;
  }

  &_none {
    margin-bottom: 0;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    z-index: 50;
    &_left {
      left: 16px;
      min-width: 18px;
    }
    &_right {
      right: 16px;
    }
  }

  &-label {
    margin-bottom: 4px;
    color: var(--Base-Secondary, rgba(43, 43, 43, 0.6));
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    display: block;
  }

  &-input {
    width: 100%;
    color: var(--Base-Default, #2b2b2b);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 14px;

    border-radius: 5px;
    border: 1px solid var(--Input-Default_Stroke, rgba(43, 43, 43, 0.1));
    background: var(--Input-Default_Background, #fff);

    @extend %transition;

    &::placeholder {
      color: var(--Base-Secondary, rgba(43, 43, 43, 0.6));
    }

    @include tablet {
      font-size: 16px;
    }

    // &[readonly] {
    //   &:focus {
    //     border-color: $light-gray;
    //   }
    // }

    // &[disabled] {
    //   background: var(--gray-200-bg);
    //   color: var(--gray-200);
    //   box-shadow: none;
    // }

    &:focus:not(.Field-input_errors) {
      border: 1px solid var(--base-accent);
    }

    &_left {
      padding-left: 43px;
    }
    &_right {
      padding-right: 43px;
    }

    &_arrow {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &_xxl {
      min-height: 48px;
    }

    &_xl {
      min-height: 40px;
    }

    &_lg {
      min-height: 36px;
    }

    &_md {
      min-height: 30px;
    }

    &_sm {
      min-height: 28px;
    }

    &_errors {
      border: 1px solid var(--badges-aletts-red-text);
    }

    // &_ios {
    //   font-size: 16px;
    //   line-height: 1.2;
    // }

    &_tel {
      padding-left: 100px;
    }
  }

  &-eye {
    position: absolute;
    top: 50%;
    right: 18px;
    display: flex;
    padding: 0;
    transform: translateY(-50%);
  }

  &-error {
    margin-top: 10px;
    transition: all 0.5s ease;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: var(--badges-aletts-red-text);
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    &.entering,
    &.entered {
      animation: slideError 0.5s ease-out forwards;
    }

    &.exiting {
      animation: slideError 0.5s ease-in alternate-reverse;
    }

    &.exited {
      opacity: 0;
    }
  }

  &-select {
    position: absolute;
    left: 8px;
    top: 8px;
  }
}

@keyframes slideError {
  0% {
    transform: translateX(30px);

    opacity: 0;
  }

  100% {
    transform: translateX(0);

    opacity: 1;
  }
}
