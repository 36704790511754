.Card {
  border-radius: 10px;

  box-shadow: var(--card-bx);

  &_dark {
    background: var(--card-bg);
  }
  &_light {
    background: var(--card-light-bg);
  }
}
