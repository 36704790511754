.AdminInvoices {
  &-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }
}

html[data-theme='light'] {
}
