.Auth {
  // background-color: var(--auth-bg);
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &-card {
    padding: 30px;
    width: 100%;
    max-width: 400px;
  }
}
