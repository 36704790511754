@import '@/assets/styles/global.scss';

.Loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrap {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 36px;
    overflow: visible;
    user-select: none;
    cursor: default;

    > div {
      position: absolute;
      width: 20px;
      height: 36px;
      opacity: 0;
      animation: move 2s linear infinite;
      transform: rotate(180deg);
      color: var(--color-green);

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
      &:nth-child(4) {
        animation-delay: 0.6s;
      }
      &:nth-child(5) {
        animation-delay: 0.8s;
      }
      &:nth-child(6) {
        animation-delay: 1s;
      }
      &:nth-child(7) {
        animation-delay: 1.2s;
      }
    }
  }
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  35% {
    left: 41%;
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    left: 59%;
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    left: 100%;
    transform: rotate(-180deg);
    opacity: 0;
  }
}
