@import '@/assets/styles/global.scss';

.Account {
  max-width: 560px;
  padding: 20px 30px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &-subtitle {
    margin-bottom: 20px;
  }

  &-form {
    margin-bottom: 30px;
  }

  &-actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    gap: 20px;
  }

  &-eye {
    padding: 0;
  }
}
