@import '@/assets/styles/global.scss';

.Title {
  color: var(--base-default);

  &_xxl {
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;

    @include tablet {
      font-size: 20px;
      line-height: 38px;
    }
  }

  &_md {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    @include tablet {
      font-size: 20px;
      line-height: 38px;
    }
  }

  &_sm {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;

    @include tablet {
      font-size: 20px;
      line-height: 38px;
    }
  }
}
