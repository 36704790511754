.ViewInvoice {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: flex-end;

  &-overlay {
    background-color: rgba(#000, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-wrap {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    height: 100vh;
    padding: 30px;
    position: relative;
    z-index: 99;
  }

  &-top {
    padding: 20px;
    border-radius: 10px;
    background: var(--Base-Accent, #1a82ff);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    color: var(--Button-Accent_Text, #fff);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  &-details {
    margin-top: 30px;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }

  &-key {
    color: var(--Base-Secondary, rgba(43, 43, 43, 0.6));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    width: 100%;
    align-items: center;
    white-space: nowrap;

    &::after {
      content: '';
      display: inline-flex;
      width: 100%;
      border-bottom: 1px dashed rgba(43, 43, 43, 0.6);
      opacity: 0.2;
      margin-left: 10px;
    }
  }
  &-value {
    color: var(--Base-Default, #2b2b2b);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-left: 10px;
    width: auto;
    white-space: nowrap;

    &_copy {
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }

  &-paid {
    margin-top: 30px;
    min-width: 120px;
  }
}
