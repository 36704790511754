@import '@/assets/styles/global.scss';

.Private {
  padding: 24px 24px 50px;
  // min-height: 100vh;
  // max-height: 750px;
  max-width: 1440px;
  display: flex;
  width: 100%;
  margin: 0 auto;

  @include desktop {
    padding: 24px 12px;
  }

  @include tablet {
    padding: 0;
  }

  @include tablet {
    flex-direction: column;
  }

  &-top {
    display: none;
    align-items: center;
    justify-content: center;
    background: var(--Global-Background-Elevation2, #fff);

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    min-height: 48px;
    @include tablet {
      display: flex;
    }
  }

  &-sidebar {
    max-width: 94px;
    width: 100%;
    max-height: 750px;

    @include tablet {
      display: none;
    }
  }
  &-content {
    width: 100%;
    max-width: calc(100% - 94px);
    padding-left: 24px;

    @include tablet {
      max-width: 100%;
      padding: 12px 12px 100px;
    }
  }

  &-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 999;
    background: var(--Global-Background-Elevation2, #fff);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.1),
      0px -1px 1px 0px rgba(0, 0, 0, 0.05);
  }
}
