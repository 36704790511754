@import '@/assets/styles/global.scss';

.CustomScrollbars {
  $self: &;

  &:hover {
    #{$self} {
      &-track {
        &_vertical,
        &_horizontal {
          // background-color: rgba(#ed682b, 0.1);
        }
      }

      &-thumb {
        &_vertical,
        &_horizontal {
          background-color: var(--divider);
        }
      }
    }
  }
  &-track {
    &_horizontal {
      // background-color: rgba(#f5f5f7, 1);
      // background-color: rgba(#ed682b, 0.1);
      bottom: 0;
      width: 100%;
      border-radius: 6px;
      @extend %transition;
    }
    &_vertical {
      // background-color: rgba(#f5f5f7, 1);
      // background-color: rgba(#ed682b, 0.1);
      top: 0;
      right: 0;
      height: 100%;
      border-radius: 6px;
      @extend %transition;
    }
  }

  &-thumb {
    &_horizontal {
      // background-color: rgba(#ed682b, 0.6);
      // background-color: rgba(#e0e0e0, 1);
      border-radius: 6px;
      cursor: grabbing;
      @extend %transition;
    }
    &_vertical {
      // background-color: rgba(#ed682b, 0.6);
      // background-color: rgba(#e0e0e0, 1);
      border-radius: 6px;
      cursor: grabbing;
      @extend %transition;
    }
  }

  &-view {
    padding-right: 10px;
  }
}
